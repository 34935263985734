import Head from "next/head";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress"; //nprogress module
import PropTypes from "prop-types";
import React from "react";
import "styles/globals.css";
import "./../styles/nprogress.css"; //styles of nprogress
// Binding events. => this should display a loading bar
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

export default function MyApp(props: any) {
  const router = useRouter();
  console.log(router.route);
  const { Component, pageProps } = props;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }, []);

  // return <h1 {...props} />;

  return (
    <React.Fragment>
      <Head>
        <title>Tax API</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      {/* //     <MDXProvider components={mdxComponents}>
         <ThemeProvider theme={theme}>
           <CssBaseline />
           <Component {...pageProps} />
         </ThemeProvider>
       </MDXProvider>*/}
    </React.Fragment>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
